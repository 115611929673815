<template>
  <div>
    <el-dialog
      title="系统资源预估价"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <span>资源可获得{{ this.form.money }}知识币</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">不采用</el-button>
        <el-button type="primary" @click="handlecy()">采用</el-button>
      </span>
    </el-dialog>
    <div class="sc">
      <el-upload
        class="upload-demo"
        :action="surl"
        accept=".jpg,.jpeg,.png,.gif,.bmp,.pdf,.JPG,.JPEG,.PBG,.GIF,.BMP,.PDF,.docx,.mp3,.mp4,.xls ,.xlsx,.ppt,.pptx,.doc,.jpg,"
        :on-success="handlePreview"
        :on-remove="handleRemove"
        :before-remove="beforeRemove"
        multiple
        :limit="1"
        :on-exceed="handleExceed"
        :file-list="fileList"
      >
        <img class="score" src="@/assets/icon/downla.png" />
      </el-upload>

      <div>点击这里进行上传</div>
      <div>注:文件大小不超<span class="red">200M</span></div>
      <div class="action">
        支持格式:
        <img class="score" src="@/assets/icon/PDF48@2x.png" />
        <img class="score" src="@/assets/icon/WORD@2x.png" />
        <img class="score" src="@/assets/icon/wps@2x.png" />
        <img class="score" src="@/assets/icon/excel(2) 拷贝@2x.png" />
        <img class="score" src="@/assets/icon/ppt@2x.png" />
        <img class="score" src="@/assets/icon/txt(1)@2x.png" />
        <img class="score" src="@/assets/icon/？@2x.png" />
      </div>
    </div>
    <el-form ref="form" :model="form" label-width="120px">
      <el-form-item label="资源名称">
        <el-input v-model="form.name" disabled></el-input>
        <span style="color:red;margin-left:5px">*</span>
      </el-form-item>
      <el-form-item label="资源分类">
        <el-select v-model="form.classification" placeholder="请选择资源分类">
          <el-option label="计算机科学" value="计算机科学"></el-option>
          <el-option label="材料科学" value="材料科学"></el-option>
          <el-option label="电工电子" value="电工电子"></el-option>
          <el-option label="教育学" value="教育学"></el-option>
          <el-option label="图书情报" value="图书情报"></el-option>
          <el-option label="合成生物学" value="合成生物学"></el-option>
          <el-option label="人工智能" value="人工智能"></el-option>
          <el-option label="机械工程" value="机械工程"></el-option>
          <el-option label="其他" value="其他"></el-option>
        </el-select>
        <span style="color:red;margin-left:5px">*</span>
      </el-form-item>
      <el-form-item label="所需知识币">
        <el-select v-model="form.Price" placeholder="请选择知识币">
          <el-option label="免费" value="0"></el-option>
          <el-option label="5知识币" value="5"></el-option>
          <el-option label="10知识币" value="10"></el-option>
          <el-option label="15知识币" value="15"></el-option>
          <el-option label="20知识币" value="20"></el-option>
          <el-option label="50知识币" value="50"></el-option>
        </el-select>
        <span style="color:red;margin-left:5px">*</span>
      </el-form-item>
      <el-form-item label="资源显示页数">
        <el-select v-model="form.page" placeholder="请选择显示页数">
          <el-option label="全部" value="全部"></el-option>
          <el-option label="1页" value="1"></el-option>
          <el-option label="2页" value="2"></el-option>
          <el-option label="5页" value="5"></el-option>
        </el-select>
        <span style="color:red;margin-left:5px">*</span>
      </el-form-item>
      <el-form-item label="关键字">
        <span>&nbsp;</span>

        <div class="kws">
          <div class="kws-down">
            <span
              class="keyword"
              v-for="(item, index) in keywordList"
              :key="index"
            >
              <span>{{ item }}</span
              ><span class="kw-close" @click="removeWord(item)"></span>
            </span>
            <span class="add-kw"
              ><input
                v-model="word"
                placeholder="请输入关键字,回车确定"
                type="text"
                @keyup.enter="addWord"
            /></span>
          </div>
        </div>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="onSubmit">上传资源</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { DELETE, QUERY, UPDATE, INSERT } from "@/services/dao.js";
import { integralAddTwo } from "@/js/common/index.js";
import { dataForma } from "@/js/common/index.js";
export default {
  data() {
    return {
      keywordList: [],
      fileList: [],
      word: "",
      form: {
        id: "",
        name: "",
        classification: "",
        Price: "0",
        page: "全部",
        pageall: 0,
        file: "",
        createTime: "",
      },
      dialogVisible: false,
    };
  },
  created() {
    this.surl = this.api.LoginURL.concat("/resources/trading/resourcesUpload");
  },
  methods: {
    async onSubmit() {
      if (this.form.page == "全部") {
        this.form.page = this.form.pageall;
      }
      if (this.form.file == "") {
        this.$message({
          message: "请选择文件",
          type: "warning",
          duration: "1000",
        });
      } else if (this.form.classification == "") {
        this.$message({
          message: "请选择分类",
          type: "warning",
          duration: "1000",
        });
      } else {
        this.form.createTime = new Date();

        let info = await INSERT(
          "POST",
          "",
          'insert_ZYDocument_all(objects: {name: "' +
            this.form.name +
            '", classification: "' +
            this.form.classification +
            '", Price: "' +
            this.form.Price +
            '",read:  "' +
            0 +
            '",Time: "' +
            this.form.createTime +
            '",keyword:' +
            JSON.stringify(JSON.stringify(this.keywordList)) +
            ', page: "' +
            this.form.page +
            '",img : "' +
            this.form.img +
            '",file : "' +
            this.form.file +
            '",format : "' +
            this.form.format +
            '",Febulous : "' +
            this.form.size +
            '",pageall: "' +
            this.form.pageall +
            '",user: "' +
            this.$store.state.userinfo.id +
            '", }) {    affected_rows  returning{id}   }'
        );
        console.log(info);
        if (info.data.insert_ZYDocument_all.affected_rows == "1") {
          this.$message({
            message: "上传成功",
            type: "success",
            duration: "1000",
          });
          let users = this.$store.state.userinfo.id;
          integralAddTwo(users);
          this.$router.push({
            name: "Finish",
            query: { id: info.data.insert_ZYDocument_all.returning[0].id },
          });
        }
      }
    },
    addWord() {
      if (this.keywordList.length == 3) {
        //alert("最多只能添加3个关键字");
        this.$message({
          message: '最多只能添加3个关键字',
          type: 'warning'
        });
        return;
      }
      this.keywordList.push(this.word);
      this.word = "";
      this.$emit("getKeyword", this.keywordList);
    },
    removeWord(word) {
      this.keywordList.forEach((item, index) => {
        if (item == word) {
          this.keywordList.splice(index, 1);
        }
      });
      this.$emit("getKeyword", this.keywordList);
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handleshow() {
      this.dialogVisible = true;
    },
    handlecy() {
      this.dialogVisible = false;
      this.form.Price = this.form.money;
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    async handlePreview(file) {
      console.log(file.data);
      let info = await QUERYED(
        "post",
        "",
        'ZYDocument( where: {name: {_like: "%' +
          file.data.fileName +
          '%"}},limit: ' +
          10 +
          ", offset: " +
          0 +
          ") {id  }"
      );
      if (info.data.ZYDocument.length > 0) {
        this.$message.warning("资源已在数据库存在");
      } else {
        if (file.data.type == "mp3") {
          this.form.pageall = 1;
        } else if (file.data.type == "mp4") {
          this.form.pageall = 1;
        } else {
          this.form.pageall = file.data.page;
        }
        this.form.name = file.data.fileName;
        this.form.file = file.data.url;
        this.form.img = file.data.cover;
        this.form.money = file.data.money;
        this.form.size = file.data.fileSize;

        this.form.format = file.data.type;
        this.handleshow();
      }
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 1 个文件，本次选择了 ${
          files.length
        } 个文件，共选择了 ${files.length + fileList.length} 个文件`
      );
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
  },
};
</script>
<style scoped>
.sc {
  text-align: center;
  padding: 10px;
  margin: 10px;
  background-color: #f7f7f7;
}
.upload-demo img {
  width: 32px;
}
.action {
  margin-top: 33px;
}
.action img {
  width: 18px;
  margin-left: 6px;
}
.el-input {
  position: relative;
  font-size: 14px;
  display: inline-block;
  width: 90%;
}
.el-form-item button {
  float: right;
  margin-right: 120px;
}
.kws {
  border: 1px solid rgba(210, 210, 210, 1);
  padding: 10px;
  height: 90px;
}
.kws-up {
  padding: 10px;
  font-size: 14px;
}
.kws-down {
  position: relative;
  min-height: 38px;
}
.kws-up .sub-modify {
  float: right;
  color: #0084ff;
  cursor: pointer;
}
.kws-down .keyword {
  display: inline-block;
  height: 20px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(210, 210, 210, 1);
  border-radius: 14px;
  padding: 3px 10px;
  line-height: 20px;
  vertical-align: middle;
  margin-left: 5px;
  font-size: 12px;
}
.kws-down .keyword .kw-close {
  vertical-align: top;
  display: inline-block;
  width: 13px;
  height: 8px;
  line-height: 8px;
  margin-left: 5px;
  background-size: 8px 8px;
  background-repeat: no-repeat;
  position: relative;
  top: 2px;
  right: -5px;
  /* border: 1px solid red; */
  cursor: pointer;
}
.kws-down .keyword .kw-close:hover,
.kws-down .add-kw:hover {
  color: red;
}
.kws-down .add-kw {
  position: absolute;
  right: 0;
  bottom: 4px;
  height: 28px;
  line-height: 28px;
  margin-left: 10px;
  padding: 3px 10px;
  cursor: pointer;
  vertical-align: middle;
  font-size: 12px;
}
.kws-down .add-kw input {
  height: 26px;
}
</style>
